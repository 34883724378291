<template>
  <div style="    border: none;"
    class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
    <!-- begin:: Aside -->
    <div class="left-menu-container">
      <div class="kt-aside__brand kt-grid__item" id="kt_aside_brand">
        <div class="kt-aside__brand-logo">
          <a href="index.html">
            <svg xmlns="http://www.w3.org/2000/svg" width="22.781" height="17.09" viewBox="0 0 22.781 17.09">
              <g id="Group_113" data-name="Group 113" transform="translate(-57.216 -412.597)">
                <path id="Path_137" data-name="Path 137"
                  d="M79.235,415.385a1.572,1.572,0,0,0-1.564-.073l-1.688.852a1.84,1.84,0,0,0-1.011,1.657v6.642a1.848,1.848,0,0,0,1.011,1.659l1.687.85a1.563,1.563,0,0,0,.719.175,1.594,1.594,0,0,0,.846-.246A1.627,1.627,0,0,0,80,425.513v-8.74a1.629,1.629,0,0,0-.762-1.388Z"
                  fill="#2d8cff"></path>
                <path id="Path_138" data-name="Path 138"
                  d="M68.5,429.681H61.9a4.452,4.452,0,0,1-4.684-4.623v-7.831A4.455,4.455,0,0,1,61.9,412.6h6.6a4.453,4.453,0,0,1,4.684,4.624v7.831a4.452,4.452,0,0,1-4.684,4.623Z"
                  fill="#2d8cff"></path>
              </g>
            </svg>
            <label class="main-menu-label">Live Room</label>
          </a>
        </div>
      </div>
      <!-- end:: Aside -->
      <!-- begin:: Aside Menu -->
      <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
        <div id="kt_aside_menu" class="kt-aside-menu kt-aside-menu--dropdown" data-ktmenu-vertical="1"
          data-ktmenu-dropdown="1" data-ktmenu-scroll="0">
          <ul class="left-main-menu">
            <li>
              <a style="    cursor: pointer;"  v-on:click="goToParticipants()">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.935" height="28.669" viewBox="0 0 22.935 28.669">
                  <g id="_-System-Icons" data-name="🔍-System-Icons" transform="translate(-8 -4)">
                    <g id="ic_fluent_person_48_regular" transform="translate(8 4)">
                      <path id="_-Color" :style="$route.name == 'participants' ? 'fill: #2d8cff' : ''" data-name="🎨-Color"
                        d="M27.889,21.2a3.045,3.045,0,0,1,3.042,2.9l0,.148v.538a6.828,6.828,0,0,1-3.526,6.006,15.476,15.476,0,0,1-7.941,1.878,15.476,15.476,0,0,1-7.941-1.878A6.835,6.835,0,0,1,8,25.028l0-.243v-.538A3.045,3.045,0,0,1,10.9,21.2l.148,0Zm0,1.792H11.046A1.254,1.254,0,0,0,9.8,24.144l0,.1v.538a5.043,5.043,0,0,0,2.666,4.476,13.725,13.725,0,0,0,7.009,1.616,13.725,13.725,0,0,0,7.009-1.616,5.051,5.051,0,0,0,2.663-4.267l0-.209v-.538A1.254,1.254,0,0,0,27.992,23ZM19.468,4A7.167,7.167,0,1,1,12.3,11.167,7.167,7.167,0,0,1,19.468,4Zm0,1.792a5.375,5.375,0,1,0,5.375,5.375A5.375,5.375,0,0,0,19.468,5.792Z"
                        transform="translate(-8 -4)" fill="#2d8cff"></path>
                    </g>
                  </g>
                </svg>
                <!-- <label class="main-menu-label">Manage Participants</label> -->
              </a>
              <span v-if="$route.name == 'participants'" class="borderRight"></span>
            </li>
            <li>
              <a style="cursor: pointer;" v-on:click="goToMedia()">
                <svg id="media-library-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                  viewBox="0 0 32 32">
                  <path :style="$route.name == 'MediaManager' ? 'fill: #2d8cff' : ''" id="Path_494" data-name="Path 494" d="M13,15v8h0l7-4Z" fill="#b6b6b6"></path>
                  <path :style="$route.name == 'MediaManager' ? 'fill: #2d8cff' : ''" id="Path_495" data-name="Path 495"
                    d="M26,28H6a2,2,0,0,1-2-2V12a2,2,0,0,1,2-2H26a2,2,0,0,1,2,2V26A2,2,0,0,1,26,28ZM6,12V26H26V12Z"
                    fill="#b6b6b6"></path>
                  <rect id="Rectangle_260" :style="$route.name == 'MediaManager' ? 'fill: #2d8cff' : ''" data-name="Rectangle 260" width="20" height="2" transform="translate(6 6)"
                    fill="#b6b6b6"></rect>
                  <rect id="Rectangle_261" :style="$route.name == 'MediaManager' ? 'fill: #2d8cff' : ''" data-name="Rectangle 261" width="16" height="2" transform="translate(8 2)"
                    fill="#b6b6b6"></rect>
                  <rect id="_Transparent_Rectangle_"  data-name="<Transparent Rectangle>" width="32" height="32"
                    fill="none"></rect>
                </svg>
                <!-- <label class="main-menu-label">Media Manager</label> -->

              </a>
              <span v-if="$route.name == 'media'" class="borderRight"></span>
            </li>
            <li>
              <a style="    cursor: pointer;" v-on:click="goToCalendar()">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.794" height="25.054" viewBox="0 0 22.794 25.054">
                  <g id="Group_115" data-name="Group 115" transform="translate(-57.604 -590.556)">
                    <path  :style="$route.name == 'calendar' ? 'fill: #2d8cff' : ''"  id="Path_125" data-name="Path 125"
                      d="M62.8,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                      fill="#b6b6b6"></path>
                    <path  :style="$route.name == 'calendar' ? 'fill: #2d8cff' : ''"  id="Path_126" data-name="Path 126"
                      d="M67.867,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                      fill="#b6b6b6"></path>
                    <path  :style="$route.name == 'calendar' ? 'fill: #2d8cff' : ''"  id="Path_127" data-name="Path 127"
                      d="M72.921,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                      fill="#b6b6b6"></path>
                    <path  :style="$route.name == 'calendar' ? 'fill: #2d8cff' : ''"  id="Path_128" data-name="Path 128"
                      d="M62.8,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,62.8,609Z"
                      fill="#b6b6b6"></path>
                    <path  :style="$route.name == 'calendar' ? 'fill: #2d8cff' : ''"  id="Path_129" data-name="Path 129"
                      d="M67.867,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,67.867,609Z"
                      fill="#b6b6b6"></path>
                    <path  :style="$route.name == 'calendar' ? 'fill: #2d8cff' : ''"  id="Path_130" data-name="Path 130"
                      d="M72.921,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,72.921,609Z"
                      fill="#b6b6b6"></path>
                    <path width="20" :style="$route.name == 'calendar' ? 'fill: #2d8cff' : ''"  id="Path_131" data-name="Path 131"
                      d="M74.743,591.7a1.139,1.139,0,0,0-2.278,0v.659H65.544V591.7a1.139,1.139,0,0,0-2.278,0v.7a6.048,6.048,0,0,0-5.655,6.381v10.3A6.488,6.488,0,0,0,59.42,613.9a6.664,6.664,0,0,0,4.764,1.708h9.633a6.719,6.719,0,0,0,4.762-1.679,6.355,6.355,0,0,0,1.811-4.757V598.781a5.9,5.9,0,0,0-5.646-6.384Zm-2.278,2.936v.811a1.139,1.139,0,0,0,2.278,0v-.753a3.964,3.964,0,0,1,2.271.987,4.069,4.069,0,0,1,1.1,3.1v.206H59.889v-.2A4.085,4.085,0,0,1,61,595.68a3.98,3.98,0,0,1,2.269-.988v.752a1.139,1.139,0,0,0,2.278,0v-.813Zm-12.577,6.627v7.818a4.234,4.234,0,0,0,1.116,3.178,4.412,4.412,0,0,0,3.179,1.072h9.633a4.472,4.472,0,0,0,3.189-1.049,4.1,4.1,0,0,0,1.106-3.11v-7.909Z"
                      fill="#b6b6b6"></path>
                  </g>
                </svg>
                <!-- <label class="main-menu-label " :class="$route.name == 'calendar' ? 'activeText' : ''">Calendar</label> -->
              </a>
                 <span v-if="$route.name == 'calendar'" class="borderRight"></span>
            </li>
            <li>
              <a style="    cursor: pointer;" v-on:click="goToSettings()" >
                <svg xmlns="http://www.w3.org/2000/svg" width="22.778" height="22.765" viewBox="0 0 22.778 22.765">
                  <g id="Group_116" data-name="Group 116" transform="translate(-57.632 -682.86)">
                    <path :style="$route.name == 'settings' ? 'fill: #2d8cff' : ''" id="Path_123" data-name="Path 123"
                      d="M69.021,692.347a1.9,1.9,0,1,0,1.342.556,1.9,1.9,0,0,0-1.342-.556Zm-3.8,1.9a3.8,3.8,0,1,1,1.112,2.683,3.794,3.794,0,0,1-1.112-2.683Z"
                      fill="#b6b6b6"></path>
                    <path :style="$route.name == 'settings' ? 'fill: #2d8cff' : ''"  id="Path_124" data-name="Path 124"
                      d="M69.021,684.758a.949.949,0,0,0-.949.948v.165a2.513,2.513,0,0,1-1.525,2.3.99.99,0,0,1-.25.069,2.514,2.514,0,0,1-2.59-.577l-.008-.006-.057-.057a.956.956,0,0,0-.308-.206.941.941,0,0,0-.727,0,.955.955,0,0,0-.308.206h0a.947.947,0,0,0-.206,1.034.95.95,0,0,0,.206.308l.065.065a2.577,2.577,0,0,1-1.778,4.364h-.107a.949.949,0,1,0,0,1.9h.165a2.523,2.523,0,0,1,1.795,4.287l-.007.008-.057.057a.947.947,0,0,0,0,1.342h0a.925.925,0,0,0,.308.206.941.941,0,0,0,.727,0,.925.925,0,0,0,.308-.206l.065-.065a2.578,2.578,0,0,1,4.365,1.777v.108a.949.949,0,0,0,1.9,0v-.165a2.526,2.526,0,0,1,4.289-1.8l.008.007.057.057a.956.956,0,0,0,.308.206.953.953,0,0,0,.727,0,.955.955,0,0,0,.308-.206h0a.948.948,0,0,0,0-1.342l-.065-.065a2.526,2.526,0,0,1,1.795-4.288h.089a.949.949,0,1,0,0-1.9H77.4a2.512,2.512,0,0,1-2.3-1.523.95.95,0,0,1-.068-.25,2.513,2.513,0,0,1,.576-2.589l.007-.008.057-.057a.947.947,0,0,0,0-1.342h0a.925.925,0,0,0-.308-.206.941.941,0,0,0-.727,0,.925.925,0,0,0-.308.206l-.065.064A2.526,2.526,0,0,1,69.97,685.8v-.09a.949.949,0,0,0-.278-.671.952.952,0,0,0-.672-.277Zm7.023,12.333.868.383a.616.616,0,0,0,.121.677l.052.053a2.846,2.846,0,0,1,0,4.028l-.671-.672.672.67a2.847,2.847,0,0,1-4.028,0h0l-.052-.052a.616.616,0,0,0-.678-.121h-.009a.619.619,0,0,0-.374.563v.159a2.847,2.847,0,0,1-5.694,0v-.072a.616.616,0,0,0-.4-.555l-.056-.023a.616.616,0,0,0-.678.121l-.052.052a2.848,2.848,0,1,1-4.028-4.027l.052-.052a.617.617,0,0,0,.121-.678v-.009a.618.618,0,0,0-.563-.373h-.165a2.847,2.847,0,1,1,0-5.693h.072a.616.616,0,0,0,.556-.4c.007-.019.014-.037.023-.055a.618.618,0,0,0-.121-.678l-.052-.052a2.847,2.847,0,1,1,4.028-4.022l.052.052a.621.621,0,0,0,.678.121.965.965,0,0,1,.189-.061.617.617,0,0,0,.27-.506v-.16a2.847,2.847,0,0,1,5.694,0v.083a.618.618,0,0,0,.374.563h.009a.616.616,0,0,0,.678-.121l.052-.052a2.848,2.848,0,1,1,4.028,4.027h0l-.052.052a.616.616,0,0,0-.121.677.954.954,0,0,1,.06.189.614.614,0,0,0,.507.27h.159a2.846,2.846,0,1,1,0,5.693h-.083a.618.618,0,0,0-.563.373Z"
                      fill="#b6b6b6"></path>
                  </g>
                </svg>
                <!-- <label class="main-menu-label "  :class="$route.name == 'settings' ? 'activeText' : ''">Settings</label> -->
              </a>
                 <span v-if="$route.name == 'settings'" class="borderRight"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end:: Aside Menu -->
  </div>
</template>

<script>
import { RRule, RRuleSet, rrulestr } from "rrule";
import VueTimepicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";
import { webinaroneTime, CheckContacts, editSaveContacts, checkContactEmail, singlewebinardata, userEmail } from "../services/service";
import Datepicker from "vue3-datepicker";
import {
  webinarmalti,
  checkWebinarUrl,
  getMyContacts,
  lastwebinarlist,
  deleteContacts,
  addToContacts
} from "../services/service";
var moment = require("moment-timezone");
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import axios from "axios";
export default {
  name: "WebinarModel",
  components: {
  },
  props: {
    data: Object,
    mode: String,
  },
  data() {
    return {
    };
  },
  async created() {
  },
  computed: {
  },
  methods: {
    async goToParticipants() {
      this.$router.push("/participants");
    },
    async goToMedia() {
      this.$router.push("/media-manager");
    },
    async goToCalendar() {
      this.$router.push("/calendar");
    },
    async goToSettings() {
      this.$router.push("/settings");
    },

  },
};
</script>

<style>

.activeText{
  color: #2d8cff !important;
    font-weight: bold;
}

.borderRight {
    background-color: #2d8cff;
    width: 4px;
    height: 40px;
    position: absolute;
    right: 0;
    border-radius: 14px;
}

.left-main-menu > li{

display: grid;
}
</style>