<template>
  <body
    class="dark kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <section class="" style="background-color: #101826; height: 100vh;">
      <div class="container-fluid py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-11">
            <div class="card border-0" style="border-radius: 1rem; background-color: #101826;">
              <div class="row g-0 signup-container" style="justify-content: center;">
                <div class="col-md-12 col-lg-5 d-flex align-items-center"
                  style="justify-content: center; position: relative;">
                  <div class="card-body" v-if="data_loader" style="
                      display: flex;
                      justify-content: center;
                      position: absolute;
                    ">
                    <span class="cutome-spinner"></span>
                  </div>
                  <div class="card-body" :class="is_updated">
                    <div style="display: flex; justify-content: space-between;">
                      <h1 class="fw-normal mb-3 pb-3 text-white" style="letter-spacing: 1px;">
                        Change Password <br />
                      </h1>
                      <button v-on:click="BackToDashboard()" class="btn btn-brand fw-normal mb-3 pb-3 text-white"
                        style="background: #b60808; border: #b60808;">
                        Back
                      </button>
                    </div>
                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example27">Password</label>

                      <div class="form-outline input-group mb-4">
                        <input v-if="showPassword" type="text" id="form2Example27" class="form-control form-control-lg my"
                          v-model="currentPassword" />
                        <input v-else type="password" id="form2Example27" class="form-control form-control-lg my"
                          v-model="currentPassword" />
                        <div class="input-group-append" @click="hidePassword">
                          <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg"
                              width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                              <path
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                              <path
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg></span>
                        </div>
                      </div>
                    </div>
                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example27">New Password</label>

                      <div class="form-outline input-group mb-4">
                        <input v-if="showNewPassword" type="text" id="form2Example27" class="form-control form-control-lg my"
                          v-model="newPassword" />
                        <input v-else type="password" id="form2Example27" class="form-control form-control-lg my"
                          v-model="newPassword" />
                        <div class="input-group-append" @click="hideNewPassword">
                          <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg"
                              width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                              <path
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                              <path
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg></span>
                        </div>
                      </div>
                    </div>

                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example27">Confirm Password</label>

                      <div class="form-outline input-group mb-4">
                        <input v-if="showConfirmNewPassword" type="text" id="form2Example27" class="form-control form-control-lg my"
                          v-model="confirmNewPassword" />
                        <input v-else type="password" id="form2Example27" class="form-control form-control-lg my"
                          v-model="confirmNewPassword" />
                        <div class="input-group-append" @click="hideConfirmNewPassword">
                          <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg"
                              width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                              <path
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                              <path
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg></span>
                        </div>
                      </div>
                    </div>
                 

                    <div class="pt-1 mb-4">
                      <button v-on:click="changePassword()" :disabled="save_d" style="position: relative;"
                        class="btn btn-primary btn-lg btn-block">
                        Save

                        <span v-if="loader" class="spinner spinner-white spinner-right"></span>
                      </button>
                      <a href="#!" class="mt-3" style="color: #2d8cff;float: right">
                        <router-link to="calendar">Back to Dashboard</router-link>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="cropImage" style="z-index: 9999;background: rgb(0 0 0 / 92%);" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width: fit-content;" role="document">
          <div class="modal-content">
            <div class="border-dotted">
              <div class="modal-body" style="border: 1px solid;
        border-radius: 6px;">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <h1 class="text-white">
                          Crop Picture
                        </h1>
                      </div>
                    </div>
                    <div class="row mt-3 mb-3" style="justify-content: center;">
                      <div>
                        <v-dialog v-model="dialog" width="500">
                          <v-card>
                            <v-card-text>
                              <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile" alt="Source Image">
                              </VueCropper>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 text-center align-items-center justify-content-center">
                  <button @click="saveImage(), (dialog = false)" type="button" class="btn white-btn mr-3"
                    style="width: 150px; position: relative" :disabled="edit_contact_enable">
                    <span v-if="edit_loader" class="spinner spinner-white spinner-right"></span>
                    Save
                  </button>
                  <button type="button" class="btn transparent-btn border-blue" style="width: 150px"
                    @click="closeModal()">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
import { mapState } from 'vuex';
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import {
  lastwebinarlist,
  signUpPost,
  changePasswordFromProfile,
  updateUserDetails,
  userEmail,
} from "../services/service";
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  components: { VueCropper, vSelect },
  props: ['image_name'],
  name: "My Profile",
  data() {
    return {
      Name: "",
      email: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      loader: false,
      showPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
    };
  },
  async mounted() {
    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    }
  },
  async created() {
    let data = await userEmail();
    console.log("data-0-----", data);
    if (data.response) {
      if (data.response.data.code == 401 || data.response.status == 401) {
        localStorage.clear();
        swal(data.response.data.message);
        this.$router.push("/login");
      }
    }

    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    } else {
      this.updateData();
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    saveImage() {
      console.log('this.image_name-----------')
      console.log('this.cropedImage-----------')

      // const userId = this.$route.params.user_id
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData()
        formData.append('profile_photo', blob, 'name.jpg')

        this.image_name = this.cropedImage
        console.log('blob-----------', blob)
        console.log('this.cropedImage-----------', this.cropedImage)
        console.log('formData-----------', formData)


        // this.mime_type = "";
        // this.selectedFile = "";
        // this.cropedImage = "";
        // this.image_name = "";
        $("#cropImage").modal("hide");
        // axios
        //   .post('/api/user/' + userId + '/profile-photo', formData)
        //   .then((response) => {
        //   })
        //   .catch(function (error) {
        //     console.log(error)
        //   })
      }, this.mime_type)
    },
    cropModel() {
      // this.selectedFile = "";
      $("#cropImage").modal("show");
    },
    closeModal() {
      $("#cropImage").modal("hide");
    },
    onFileSelect(e) {
      const file = e.target.files[0]
      if (this.selectedFile) {
        $("#cropImage").modal("show");
      }
      console.log('file_________', file);
      this.mime_type = file.type
      console.log(this.mime_type)
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)

          $("#cropImage").modal("show");
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    async updateData() {
      const body = {
        is_edit: 0,
      };
      const userData = await updateUserDetails(body);
      this.email = userData.data.result.data.email;
    },

    isCompanyValid: async function async() {
      console.log("this.---------", this.companyname);

      var old_url =
        window.location.origin +
        "/" +
        this.companyname.toLocaleLowerCase().replace(/[^a-z0-9]/gi, "") +
        "/";
      this.url = old_url;
      this.processInput();
      // this.companyname
    },
    async processInput() {
      const output = document.querySelector(".output");
      if (output) {
        output.style.fontSize = "18px"; // Default font size
        this.resize_to_fit();

      }
    },
    async resize_to_fit() {
      const output = document.querySelector(".output");
      let fontSize = window.getComputedStyle(output).fontSize;
      output.style.fontSize = parseFloat(fontSize) - 1 + "px";

      const outputContainer = document.querySelector(".container");

      if (output.clientHeight >= outputContainer.clientHeight) {
        this.resize_to_fit();
      }
    },
    async myFunction() {
      // Get the text field
      var copyText = document.getElementById("myInput");

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);

      // Alert the copied text
      alert("Copy Text to Clipboard ");
    },

    async BackToDashboard() {
      this.$router.push("/profile");
    },
    async hideConfirmNewPassword() {
      this.showConfirmNewPassword = !this.showConfirmNewPassword;
    },
    async hideNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    async hidePassword() {
      this.showPassword = !this.showPassword;
    },
    async changePassword() {

      var body = {
        password: this.currentPassword,
        newPassword: this.newPassword,
        is_update: 1,
        email: this.email
      };
      console.log('body---', body)
      if (this.currentPassword == "") {
        swal("Please enter current password.");
      } else if (this.newPassword == "") {
        swal("Please enter new password.");
      } else {
        if (this.newPassword == this.confirmNewPassword) {
          this.loader = true;

          // let data = await 
          const data = await changePasswordFromProfile(body);
          if (data.data.result == 1) {
            swal({
              title: "Password updated",
              text:
                "Password has been updated. please login with new password",
              icon: "success",
              dangerMode: false,
            }).then((willDelete) => {
              if (willDelete) {
                this.$router.push("/profile");
              } else {
                this.$router.push("/profile");
              }
            });
          } else if (data.data.result == 2) {
            swal(data.data.message);

          } else if (data.data.result == 3) {
            swal(data.data.message);
          } else {
            this.is_valid = false;
            this.is_not_valid = true;
          }
          this.loader = false;
        } else {
          swal("Passowrd do not match.");
        }
      }
    },
  },
};
</script>

<style>
.my {
  background: white !important;
}

.cutome-spinner {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

.card-opacity {
  opacity: 0.2;
}

.user {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border: 3px solid #ffffff;
  position: relative;
}

.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.icons {
  position: absolute;
  top: 10px;
  right: 0;
  background: #e2e2e2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  color: #0000ff;
  font-size: 14px;
  cursor: pointer;
}

.vs__selected {
  color: white !important;
}
</style>
