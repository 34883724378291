<template>
  <body
    class="dark kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading"
  style="background-color: rgb(16, 24, 38);">
    <section class="" style="background-color: #101826;">
      <div class="container-fluid py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-11">
            <div
              class="card border-0"
              style="border-radius: 1rem; background-color: #101826;"
            >
              <div class="row g-0 signup-container">
                <div class="col-md-6 col-lg-5 d-flex align-items-center">
                  <div class="card-body p-4 p-lg-5">
                    <h1
                      class="fw-normal mb-3 pb-3 text-white"
                      style="letter-spacing: 1px;"
                    >
                      SignUp <br />
                    </h1>
                    <div class="row">
                      <div class="col-md-6 form-outline mb-4">
                        <label
                          class="form-label text-white"
                          for="form2Example17"
                          >First Name</label
                        >

                        <input
                          type="text"
                          id="form2Example17"
                          class="form-control form-control-lg my"
                          v-model="firstName"
                        />
                      </div>
                      <div class="col-md-6 form-outline mb-4">
                        <label
                          class="form-label text-white"
                          for="form2Example17"
                          >Last Name</label
                        >

                        <input
                          type="text"
                          id="form2Example17"
                          class="form-control form-control-lg my"
                          v-model="lastName"
                        />
                      </div>
                    </div>
                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example17"
                        >Company name</label
                      >

                      <input
                        type="companyname"
                        id="form2Example17" style="text-transform: lowercase;"
                        class="form-control form-control-lg my"
                        v-model="companyname"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example17"
                        >Email address</label
                      >

                      <input
                        type="email"
                        id="form2Example17"
                        class="form-control form-control-lg my"
                        v-model="email"
                      />
                    </div>

                    <label class="form-label text-white" for="form2Example27"
                      >Password</label
                    >

                    <div class="form-outline input-group mb-4">
                      <input
                        v-if="showPassword"
                        type="text"
                        id="form2Example27"
                        class="form-control form-control-lg my"
                        v-model="password"
                      />
                      <input
                        v-else
                        type="password"
                        id="form2Example27"
                        class="form-control form-control-lg my"
                        v-model="password"
                      />
                      <div class="input-group-append" @click="hidePassword">
                        <span class="input-group-text" id="basic-addon2"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                            />
                            <path
                              d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                            /></svg
                        ></span>
                      </div>
                    </div>

                    <label class="form-label text-white" for="form2Example27">
                      Confirm password</label
                    >

                    <div class="form-outline input-group mb-4">
                      <input
                        v-if="showPassword"
                        type="text"
                        id="form2Example27"
                        class="form-control form-control-lg my"
                        v-model="confirmPassword"
                      />
                      <input
                        v-else
                        type="password"
                        id="form2Example27"
                        class="form-control form-control-lg my"
                        v-model="confirmPassword"
                      />
                      <div class="input-group-append" @click="hidePassword">
                        <span class="input-group-text" id="basic-addon2"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                            />
                            <path
                              d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                            /></svg
                        ></span>
                      </div>
                    </div>

                    <a class="small" href="#!"> <router-link to="forgot-password">Forgot password? </router-link></a>

                    <div class="pt-1 mb-4">
                      <button
                        v-on:click="signUpTest()"
                        style="position: relative;"
                        class="btn btn-primary btn-lg btn-block"
                        :disabled="loader"
                      >
                        SignUp
                        <span
                          v-if="loader"
                          class="spinner spinner-white spinner-right"
                        ></span>
                      </button>
                    </div>

                    <p class="mb-5 pb-lg-2" style="color: #ffff;">
                      Don't have an account?
                      <a href="#!" style="color: #2d8cff;">
                        <router-link to="login">Login</router-link>
                      </a>
                    </p>
                  </div>
                </div>

                <div
                  class="col-md-6 col-lg-7 d-none d-md-block signup-image"
                  style="
                    color: #78b0ed;
                    background-image: linear-gradient(
                      200deg,
                      rgba(172, 206, 247, 1),
                      rgba(240, 210, 238, 1)
                    );
                    border-radius: 1rem 1rem;
                  "
                >
                <div class="fw-normal mt-5 mb-2 pb-5 text-dark text-center">
                    <img alt="Logo" width="350" height="62" src="../../assets/media/UW@600x.png" />
                  </div>
                  <div class="text-center mb-5" style="color: #3e4c64;">
                    <img
                      src="../../assets/media/broadcast.jpg"
                      alt="signup form"
                      class="img-fluid w-50"
                      style="border-radius: 1rem 0 0 1rem;"
                    />
                    <h4>The <b>Most Powerful</b> webinar software</h4>
                    <p>
                      webinar software that puts you in control of your
                      broadcasts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
import { signUpPost, webinaroneTime } from "../services/service";

export default {
  name: "Sign-Up",
  data() {
    return {
      Name: "",
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      companyname: "",
      confirmPassword: "",
      showPassword: false,
      loader: false,
    };
  },
  mounted() {
    if (localStorage.getItem("isAuthenticated")) {
      this.$router.push("/calendar");
    }
  },
  methods: {
    async hidePassword() {
      this.showPassword = !this.showPassword;
    },
    async signUpTest() {
      var random_id = await this.makeid(7);

      let body = {
        Name: random_id,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        password: this.password,
        verifiedEmail: true,
        companyName: this.companyname.toLocaleLowerCase().replaceAll(" ", ""),
      };

      if (this.firstName == "") {
        swal("Please enter your first name.");
      } else if (this.lastName == "") {
        swal("Please enter your last name.");
      } else if (this.email == "") {
        swal("Please enter your email.");
      } else if (this.companyname == "") {
        swal("Please enter your company name.");
      } else if (this.password == "") {
        swal("Please enter your password.");
      } else if (this.confirmPassword == "") {
        swal("Please enter Confirm password.");
      } else if (this.confirmPassword != this.password) {
        swal("Password and confirm password are not same.");
      } else if (this.password.length <= 5) {
        swal("password is too small! minimum 6 character are required.");
      } else {
        this.loader = true;

        console.log(random_id);
        let data = await signUpPost(body);
        if (data.status == 200) {
          this.is_loading = "";
          this.Name = random_id;
          this.companyname = "";
          this.email = "";
          this.password = "";
          this.confirmPassword = "";

          swal(data.data.message);
          this.loader = false;
          this.$router.push("/login");
        } else {
          swal(data.response.data.message);
          this.loader = false;
        }
      }
    },
    async makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
  },
};
</script>
<style>
.my {
  background: white !important;
}
body{
 background-color: rgb(16, 24, 38);
}
</style>
