export class CaptureStream{

    constructor(file){
       this.fileNode = file;
       this.resolver = null;
       this.generatedStream = new Promise((resolve)=>this.resolver = resolve);
       this.canvas = null;
       this.ctx = null;
       this.audioCtx = null;
       this.source = null;
       this.destination = null;
       this.frameID = null;
       this.processStream()
    }

    processFrame() {
        this.ctx.drawImage(this.fileNode, 0, 0);
        window.requestAnimationFrame(()=>this.processFrame());
    }

    processStream(){
        if (typeof HTMLVideoElement?.prototype?.captureStream === 'function') {
            this.resolver(this.fileNode.captureStream())
        }
        else if(typeof HTMLVideoElement?.prototype?.mozCaptureStream === 'function'){
            this.resolver(this.fileNode.mozCaptureStream());
        }
        else{
            console.log('Capture stream fetching in else::::::::',this.fileNode);
                this.canvas = document.createElement("canvas");
                this.ctx = this.canvas.getContext("2d")
                this.canvas.width = this.fileNode.videoWidth;
                this.canvas.height = this.fileNode.videoHeight;
                this.frameID = window.requestAnimationFrame(()=>this.processFrame())
                this.audioCtx = new AudioContext()
                this.source = this.audioCtx.createMediaElementSource(this.fileNode)
                this.destination = this.audioCtx.createMediaStreamDestination()
                this.source.connect(this.destination)
                this.source.connect(this.audioCtx.destination)
                const mediaStream = new MediaStream([this.canvas.captureStream(30).getVideoTracks()[0],this.destination.stream.getAudioTracks()[0]])
                this.resolver(mediaStream)
        }
    }

    async close(){
        if(this.frameID){
            window.cancelAnimationFrame(this.frameID)
            this.frameID = null;
        }
        this.resolver = null;
        const stream = await this.generatedStream;
        stream.getTracks()?.forEach((track)=>{
            track?.stop()
        })
        this.generatedStream = null;
        this.canvas = null;
        this.ctx = null;
        this.source?.disconnect()
        this.source = null;
        this.destination = null;
        await this.audioCtx?.close()
        this.audioCtx = null;
    }

}