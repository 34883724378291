<template>
  <body
    class="dark kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading"
  >
    <section class="" style="background-color: #101826; height: 100vh">
      <div class="container-fluid py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-11">
            <div
              class="card border-0"
              style="border-radius: 1rem; background-color: #101826"
            >
              <div
                class="row g-0 signup-container"
                style="justify-content: center"
              >
                <div
                  class="col-md-12 col-lg-6 d-flex align-items-center"
                  style="justify-content: center; position: relative"
                >
                  <div
                    class="card-body"
                    v-if="data_loader"
                    style="
                      display: flex;
                      justify-content: center;
                      position: absolute;
                    "
                  >
                    <span class="cutome-spinner"></span>
                  </div>
                  <div class="card-body">
                    <div class="modal-content" style="background: #fff">
                      <div class="border-dotted">
                        <div class="modal-body">
                          <div class="row" style="margin-top: 14px">
                            <div class="col-md-12">
                              <h3 class="text-dark weight-700">Attention:</h3>
                              <hr />
                              <h4 class="text-dark weight-400">
                                You have been removed from the webinar. Thank
                                you for attending.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="row mt-3 text-center align-items-center justify-content-center"
                        style="margin-bottom: 32px"
                      >
                        <button
                          v-on:click="BackToDashboard()"
                          type="button"
                          id="BtnNotReadableError"
                          class="btn white-btn mr-3"
                          style="width: 150px; background: #b60808; color: #fff"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  name: "My Profile",
  data() {
    return {
      Name: "",
    };
  },
  async mounted() {},
  async created() {},
  computed: {},
  methods: {
    BackToDashboard() {
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.my {
  background: white !important;
}

.cutome-spinner {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

.card-opacity {
  opacity: 0.2;
}

.user {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border: 3px solid #ffffff;
  position: relative;
}

.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.icons {
  position: absolute;
  top: 10px;
  right: 0;
  background: #e2e2e2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  color: #0000ff;
  font-size: 14px;
  cursor: pointer;
}

.vs__selected {
  color: white !important;
}
</style>
